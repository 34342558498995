
.signbtn {
  font-family: PoppinsBold;
  font-size: 12px;
  line-height: 18px;
  padding: 10px 41px;
  border-radius: 10px;
  margin-left: 18px;
  margin-top: -5px;
  background: #FFFFFF;
  border: 1px solid #E2E2EA;
  color: #696974;
  &:hover {
    color: #696974;
  }
  &.active {
    background-color: #01A3A4 !important;
    border: 1px solid #01A3A4;
    color: #fff;
  }

  @media (max-width: 767px) {
    font-size: 11px;
    padding: 2px 9px;
    margin-top: 5px;
  }
}
.logoutbtn {
  font-family: PoppinsBold;
  font-size: 12px;
  line-height: 18px;
  padding: 10px 41px;
  border-radius: 10px;
  color: #01A3A4;
  background: #FFFFFF;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
  @media (max-width: 767px) {
    font-size: 11px;
    padding: 2px 9px;
    margin-top: 5px;
  }
}
