.button-tooltip {
   .tooltip-inner {
     background-color: #fff !important;
     padding: 20px 34px;
     max-width: 276px;
     border-radius: 10px;
     box-shadow: 1px 1px 2px 2px #888888;
   }
   .arrow:before {
     border-bottom-color:  #888888 !important;
   }
   h3 {
     font-family: PoppinsLight;
     font-style: normal;
     font-weight: 500;
     font-size: 18px;
     line-height: 27px;
     text-align: center;
     letter-spacing: 0.1px;
     color: #000000;
   }
   p {
    font-family: RobotoLight;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #696974;
   }
}