@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@200;300;400;500;600;700;800;900&display=swap');


// Poppins
@font-face {
  font-family: PoppinsBlack;
  src: url(../fonts/Poppins-Black.ttf);
}
@font-face {
  font-family: PoppinsBlackItalic;
  src: url(../fonts/Poppins-BlackItalic.ttf);
}
@font-face {
  font-family: PoppinsBold;
  src: url(../fonts/Poppins-Bold.ttf);
}
@font-face {
  font-family: PoppinsLight;
  src: url(../fonts/Poppins-Light.ttf);
}
@font-face {
  font-family: PoppinsMedium;
  src: url(../fonts/Poppins-Medium.ttf);
}
@font-face {
  font-family: PoppinsSemiBold;
  src: url(../fonts/Poppins-SemiBold.ttf);
}
@font-face {
  font-family: PoppinsRegular;
  src: url(../fonts/Poppins-Regular.ttf);
}
@font-face {
  font-family: PoppinsThin;
  src: url(../fonts/Poppins-Thin.ttf);
}


//Roboto
@font-face {
    font-family: RobotoBlack;
    src: url(../fonts/Roboto-Black.ttf);
  }
  @font-face {
    font-family: RobotoBlackItalic;
    src: url(../fonts/Roboto-BlackItalic.ttf);
  }
  @font-face {
    font-family: RobotoBold;
    src: url(../fonts/Roboto-Bold.ttf);
  }
  @font-face {
    font-family: RobotoLight;
    src: url(../fonts/Roboto-Light.ttf);
  }
  @font-face {
    font-family: RobotoMedium;
    src: url(../fonts/Roboto-Medium.ttf);
  }
  @font-face {
    font-family: RobotoRegular;
    src: url(../fonts/Roboto-Regular.ttf);
  }
  @font-face {
    font-family: RobotoThin;
    src: url(../fonts/Roboto-Thin.ttf);
  }

