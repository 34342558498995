.modal {
  top: 30px;
}
.modal-dialog {
  margin-top: 45px;
}
.membershipmodal .modal-dialog {
  max-width: 1037px !important;
}
.verifymodal .modal-dialog {
  max-width: 560px !important;
}
.modal-content {
  background: #fff;
  border-radius: 15px;
  padding: 27px;
  text-align: center;
  .modal-header {
    padding: 0;
  }
  .modal-title {
    margin: auto;
    font-family: PoppinsLight;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0.1px;
    color: #171725;
    width: 100%;
    h3 {
      font-size: 18px;
    }
    h2 {
      font-size: 24px;
    }
  }
  //membership modal
  .findmore p {
    font-family: PoppinsLight;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #01a3a4;
  }
  .membershiptable {
    th {
      border: none !important;
    }
    td {
      border: none;
      text-align: center;
      font-family: PoppinsMedium;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: 0.1px;
      color: #92929d;
      &:first-child {
        text-align: right;
        color: #000000;
      }
      &:nth-child(2) {
        border-right: 1px solid #d8d8d8;
      }
      &:nth-child(3) {
        border-right: 1px solid #d8d8d8;
      }
      &:nth-child(4) {
        border-right: 1px solid #d8d8d8;
      }
    }
  }
  .membershipname {
    font-family: PoppinsLight;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #000000;
  }
  .membershiptype {
    font-family: PoppinsLight;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #01a3a4;
  }
  .booleanBtn {
    background: #ffffff;
    border-radius: 15px;
    font-family: PoppinsLight;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #92929d;
    border: 1px solid #92939d;
    padding: 10px 0;
    width: 120px;
    &.outline {
      color: #01a3a4;
      border: 1px solid #01a3a4;
    }
  }
  .paybtn {
    padding: 9px 78px;
    font-family: PoppinsLight;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #ffffff;
    background: #01a3a4;
    border-radius: 10px;
    border: none;
    margin: auto;
    margin-top: 32px;
  }
  //verify modal
  .note {
    font-family: RobotoLight;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 1px;
    color: #b5b5be;
    margin-bottom: 20px;
  }
  .subtitle {
    font-family: PoppinsLight;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #171725;
  }
  .uploadfile {
    padding: 70px 0;
    width: 100%;
    border: 1px solid #000;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }
  .uploadfilediv {
    position: relative;
    width: 100%;
    min-height: 172px;
    height: auto;
    border: 1px solid #92929d;
    box-sizing: border-box;
    border-radius: 10px;
    .clickdoc {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-family: PoppinsLight;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.2px;
      color: #92929d;
    }
  }
  .inputinfo {
    width: 100%;
    border: none;
    border-bottom: 1px solid #e2e2ea;
    font-family: RobotoLight;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.1px;
    color: #44444f;
    padding: 10px;
  }
  .terms {
    margin: auto;
    font-family: RobotoLight;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #92929d;
  }
}
.returnbtn {
  font-family: PoppinsLight;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #ffffff;
  padding: 8px 12px;
  background: #01a3a4;
  border-radius: 10px;
  border: none;
  position: absolute;
  left: 0;
  top: -40px;
}
.modal-footer {
  @media (max-width: 767px) {
    border-top: none !important;
  }
}
